import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@chakra-ui/react';

import CategoryName from '../CategoryName/CategoryName';
import SideBarItem from '../SideBarItem/SideBarItem';

export default function SideBarCategory({
  categoryName,
  categoryItems,
  activeItemId,
  setActiveItemId
}) {
  return (
    <Box>
      <CategoryName name={categoryName} />
      {categoryItems.map(({ id, title, icon, path }) => (
        <SideBarItem
          key={id}
          id={id}
          icon={icon}
          setActiveItem={setActiveItemId}
          activeItemId={activeItemId}
          path={path}
        >
          {title}
        </SideBarItem>
      ))}
    </Box>
  );
}

SideBarCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.shape
    })
  ).isRequired,
  activeItemId: PropTypes.string,
  setActiveItemId: PropTypes.func
};
