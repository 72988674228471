import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Image, Text } from '@chakra-ui/react';

export default function BasicPlaceholder({
  imgSrc,
  imgAlt = 'Placeholder image presenting incorrect state',
  title,
  description,
  children
}) {
  return (
    <Stack pt="28" w="full" direction="column" align="center" justify="center">
      <Image src={imgSrc} alt={imgAlt} maxW="20%" loading="eager" />
      <Stack
        pt="8"
        textAlign="center"
        justify="center"
        align="center"
        direction="column"
        spacing="3"
      >
        <Text as="h4" fontSize="lg" fontWeight="bold">
          {title}
        </Text>
        <Text>{description}</Text>
        {children}
      </Stack>
    </Stack>
  );
}

BasicPlaceholder.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  imgAlt: PropTypes.string
};
