import React from 'react';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuDivider
} from '@chakra-ui/react';

import { useUser } from '../../../../../context/userContext';

import { FiUnlock, FiUser, FiSliders, FiKey } from 'react-icons/fi';

import AvatarExtended from '../../../../extended/AvatarExtended/AvatarExtended';
import MenuItemExtended from '../../../../extended/MenuItemExtended/MenuItemExtended';
import { logOutUser } from '../../../../../services/auth/authServices';

const DEFAULT_STYLES = {
  MENU_ICON_FONT_SIZE: '20px',
  MENU_ITEM_FONT_SIZE: 'md',
  MENU_GROUP_TITLE_FONT_SIZE: 'lg',
  ICON_SPACING: 3
};

export default function UserMenu() {
  const { user, setUser } = useUser();

  const handleLogOut = () => {
    logOutUser();
    setUser(undefined);
  };
  return (
    <Menu ml={2}>
      <MenuButton transition="all .3s">
        <AvatarExtended
          img="https://avatars.dicebear.com/api/bottts/:seed4.svg"
          username={`${user?.firstName} ${user?.lastName}`}
        />
      </MenuButton>
      <MenuList>
        <MenuGroup
          textAlign="center"
          fontSize={DEFAULT_STYLES.MENU_GROUP_TITLE_FONT_SIZE}
          title="Settings"
        >
          <MenuItemExtended
            icon={<FiUser fontSize={DEFAULT_STYLES.MENU_ICON_FONT_SIZE} />}
            iconSpacing={DEFAULT_STYLES.ICON_SPACING}
            fontSize={DEFAULT_STYLES.MENU_ITEM_FONT_SIZE}
          >
            Profile
          </MenuItemExtended>
          <MenuItemExtended
            icon={<FiSliders fontSize={DEFAULT_STYLES.MENU_ICON_FONT_SIZE} />}
            fontSize={DEFAULT_STYLES.MENU_ITEM_FONT_SIZE}
            iconSpacing={DEFAULT_STYLES.ICON_SPACING}
          >
            Account Settings
          </MenuItemExtended>
          <MenuItemExtended
            icon={<FiKey fontSize={DEFAULT_STYLES.MENU_ICON_FONT_SIZE} />}
            fontSize={DEFAULT_STYLES.MENU_ITEM_FONT_SIZE}
            iconSpacing={DEFAULT_STYLES.ICON_SPACING}
          >
            Privacy & Security
          </MenuItemExtended>
        </MenuGroup>
        <MenuDivider orientation="horizontal" />
        <MenuGroup
          textAlign="center"
          fontSize={DEFAULT_STYLES.MENU_GROUP_TITLE_FONT_SIZE}
          title="Options"
        >
          <MenuItemExtended
            fontSize={DEFAULT_STYLES.MENU_ITEM_FONT_SIZE}
            iconSpacing={DEFAULT_STYLES.ICON_SPACING}
            onClick={handleLogOut}
            icon={<FiUnlock fontSize={DEFAULT_STYLES.MENU_ICON_FONT_SIZE} />}
          >
            Sign Out
          </MenuItemExtended>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
