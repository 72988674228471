import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  Spinner,
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function LoadingModal({ description, isOpen, onClose }) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody py="10">
          <Stack direction="column" align="center" justify="center" spacing="6">
            <Text fontWeight="semibold" fontSize="lg">
              {description}
            </Text>
            <Spinner
              thickness="10px"
              speed="0.45s"
              emptyColor="gray.200"
              color="green.300"
              size="xl"
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

LoadingModal.propTypes = {
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
