import React from 'react';
import { Stack, Image, Heading } from '@chakra-ui/react';

export default function Logo() {
  return (
    <Stack direction="column" pt="10" pb="8" align="center">
      <Image maxW="60%" loading="eager" src="/images/logo/additionalIcon.svg" />
      <Heading
        fontSize="85px"
        letterSpacing="tighter"
        fontWeight="black"
        as="h3"
        textAlign="center"
        bgGradient="linear(to right, #a73737, #7a2828)"
        bgClip="text"
      >
        {process.env.REACT_APP_TITLE}
      </Heading>
    </Stack>
  );
}
