/* eslint-disable react/prop-types, react/display-name */
import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';

import { GLOBAL_CONSTANTS } from '../../../utils/constants/global.constants';
import { MemoChakraReactTable } from '../../../components/custom/ChakraReactTable/ChakraReactTable';
import { FiX, FiEdit, FiPlus, FiDownload, FiDelete } from 'react-icons/fi';

import { Flex, Stack, Button, Text, useToast } from '@chakra-ui/react';
import { CountryWithFlagMemo } from '../../../components/form/elements/CountryWithFlag/CountryWithFlag';
import ConfirmDialog from '../../../components/alerts/ConfirmDialog/ConfirmDialog';
import {
  useCurrenciesState,
  useCurrenciesDispatcher,
  ACTIONS
} from '../../../context/currenciesContext';

import { getFakeAlreadySubmittedCurrencies } from '../../../services/currencies/currenciesServices';
import LoadingModal from '../../../components/modals/LoadingModal/LoadingModal';
import { useTitle } from '../../../hooks/useTitle';
import BaseModal from '../../../components/modals/BaseModal/BaseModal';
import { MemoizedCurrenciesForm } from '../CurrenciesForm/CurrenciesForm';
import PageWithSidebar from '../../../components/layout/PageWithSidebar/PageWithSidebar';
import { errorToast } from '../../../utils/constants/toast.constants';
import NoDataPlaceholder from '../../../components/placeholders/NoDataPlaceholder/NoDataPlaceholder';

const AddNewCurrencyButton = ({ onClickHandler, ...other }) => {
  return (
    <Button
      leftIcon={<FiPlus />}
      onClick={onClickHandler}
      type="button"
      variant="solid"
      colorScheme="blue"
      {...other}
    >
      Add new
    </Button>
  );
};

export default function CurrenciesPage() {
  useTitle(`Currencies - ${process.env.REACT_APP_TITLE}`);

  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [currencyEditId, setCurrencyEditId] = useState();
  const currencies = useCurrenciesState();
  const dispatch = useCurrenciesDispatcher();
  const toast = useToast();

  const openEditModal = () => {
    setCurrencyEditId(selectedCurrencies[0]);
    setIsCurrencyModalOpen(true);
  };

  const closeCurrenciesModal = () => {
    setIsCurrencyModalOpen(false);
    setCurrencyEditId();
  };

  const closeConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const deleteFromCurrencies = () => {
    selectedCurrencies.length === 0
      ? dispatch({ type: ACTIONS.DROP })
      : dispatch({ type: ACTIONS.DELETE, payload: selectedCurrencies });
    closeConfirmDialog();
  };

  const handleDeleteClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDownloadClick = async () => {
    try {
      setIsLoadingModalOpen(true);
      const alreadySubmittedCurrencies = await getFakeAlreadySubmittedCurrencies();
      dispatch({ type: ACTIONS.SET, payload: alreadySubmittedCurrencies });
      setIsLoadingModalOpen(false);
    } catch (e) {
      toast(
        errorToast(
          'Ooops 🙈',
          'Our servers are currently not responding. Please try again later 🙏'
        )
      );
      setIsLoadingModalOpen(false);
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: 'From Currency',
        accessor: 'from',
        Cell: ({ value }) => (
          <CountryWithFlagMemo
            text={`1 ${value.label}`}
            flagImage={`/images/flags/${value.cc1}.svg`}
          />
        )
      },
      {
        Header: 'To Currency',
        accessor: 'to',
        Cell: ({ value }) => (
          <CountryWithFlagMemo
            text={`1 ${value.label}`}
            flagImage={`/images/flags/${value.cc1}.svg`}
          />
        )
      },
      {
        Header: 'Exchange Rate',
        accessor: 'exchangeRate',
        Cell: ({ value }) =>
          value.toFixed(GLOBAL_CONSTANTS.EXCHANGE_RATE_PRECISION),
        isNumeric: true,
        sortType: 'number'
      },
      {
        Header: 'Valid Date',
        accessor: 'validDate',
        Cell: ({ value }) => format(value, GLOBAL_CONSTANTS.DATE_FORMAT),
        sortType: 'datetime'
      },
      {
        Header: 'Date of addition',
        accessor: 'dateOfAddition',
        Cell: ({ value }) => format(value, GLOBAL_CONSTANTS.DATE_FORMAT),
        sortType: 'datetime'
      }
    ],
    []
  );

  return (
    <PageWithSidebar display="flex" px="8">
      {currencies?.length > 0 ? (
        <Flex direction="column" pt="10" w="full">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="black" fontSize="3xl" as="h3">
              Currencies
            </Text>
            <Stack direction="row" spacing="4" justify="flex-end" h="12">
              <AddNewCurrencyButton
                onClickHandler={() => setIsCurrencyModalOpen(true)}
                h="full"
              />
              <Button
                onClick={handleDownloadClick}
                type="button"
                variant="solid"
                colorScheme="blue"
                h="full"
                leftIcon={<FiDownload />}
              >
                Generate fake data
              </Button>
              {selectedCurrencies.length === 1 && (
                <Button
                  onClick={openEditModal}
                  type="button"
                  variant="solid"
                  colorScheme="teal"
                  h="full"
                  leftIcon={<FiEdit />}
                >
                  Edit selected
                </Button>
              )}
              <Button
                onClick={handleDeleteClick}
                type="button"
                variant="solid"
                colorScheme="red"
                h="full"
                leftIcon={<FiX />}
              >
                Delete {selectedCurrencies.length === 0 ? 'All' : 'Selected'}
              </Button>
            </Stack>
          </Flex>
          <MemoChakraReactTable
            isSelectable={true}
            setSelectedIds={setSelectedCurrencies}
            columns={tableColumns}
            data={currencies}
            bg="white"
            my={8}
            px={8}
            py={10}
            borderRadius="lg"
            boxShadow="lg"
          />
        </Flex>
      ) : (
        <NoDataPlaceholder dataName="currencies">
          <AddNewCurrencyButton
            onClickHandler={() => setIsCurrencyModalOpen(true)}
          />
        </NoDataPlaceholder>
      )}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onConfirm={deleteFromCurrencies}
        onCancel={closeConfirmDialog}
        title="Warning!"
        description="Please confirm. You won't be able to revert this action after confirming."
        confirmButtonText="Delete"
      />
      <LoadingModal
        description="Generating fake data..."
        isOpen={isLoadingModalOpen}
        onClose={() => setIsLoadingModalOpen(false)}
      />
      <BaseModal
        size="xl"
        motionPreset="none"
        isOpen={isCurrencyModalOpen}
        onClose={closeCurrenciesModal}
      >
        <MemoizedCurrenciesForm
          currencyId={currencyEditId}
          closeModal={closeCurrenciesModal}
        />
      </BaseModal>
    </PageWithSidebar>
  );
}
