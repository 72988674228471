import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useColorMode, chakra } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import './ChakraDatePickerStyles.css';

function ChakraDatePicker({
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  isError = false,
  ...other
}) {
  const isLight = useColorMode().colorMode === 'light';

  return (
    <chakra.div className={isLight ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        wrapperClassName={isError && 'invalid'}
        className={`react-datapicker__input-text`} //input is white by default and there is no already defined class for it so I created a new one
        {...other}
      />
    </chakra.div>
  );
}

{
  /* <Controller
        control={control}
        name={name}
        rules={validation}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid }
        }) => {
          return (
            <ChakraDatePicker
              {...other}
              placeholderText="Select currency date"
              onChange={async date => {
                onChange(date);
                await trigger(name, { shouldFocus: true });
              }}
              onBlur={onBlur}
              selectedDate={value}
              isError={invalid}
            />
          );
        }}
      /> */
}

export default function ChakraDatePickerController({
  controller: Controller,
  control,
  name,
  rules,
  defaultValue,
  trigger,
  ...other
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid }
      }) => {
        return (
          <ChakraDatePicker
            {...other}
            onChange={async date => {
              onChange(date);
              await trigger(name, { shouldFocus: true });
            }}
            onBlur={onBlur}
            selectedDate={value}
            isError={invalid}
          />
        );
      }}
    />
  );
}

ChakraDatePicker.propTypes = {
  selectedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  showPopperArrow: PropTypes.bool,
  isError: PropTypes.bool
};

ChakraDatePickerController.propTypes = {
  controller: PropTypes.func,
  control: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rules: PropTypes.object,
  trigger: PropTypes.func
};
