import React from 'react';

import { Flex, HStack } from '@chakra-ui/react';

import { LAYOUT_CONSTANTS } from '../../../../utils/constants/layout.constants';

import UserMenu from './UserMenu/UserMenu';
import Notifications from './Notifications/Notifications';

export default function NavBar() {
  return (
    <Flex
      ml={LAYOUT_CONSTANTS.SIDEBAR.WIDTH}
      h="20"
      px={4}
      alignItems="center"
      justifyContent="flex-end"
      borderBottomWidth="2px"
      boxShadow="sm"
      borderBottomColor="gray.200"
      bg="white"
    >
      <HStack spacing={6}>
        <Notifications />
        <Flex alignItems="center">
          <UserMenu />
        </Flex>
      </HStack>
    </Flex>
  );
}
