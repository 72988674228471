import React from 'react';
import PropTypes from 'prop-types';

import BasicPlaceholder from '../BasicPlaceholder/BasicPlaceholder';

export default function NoDataPlaceholder({ dataName, children }) {
  return (
    <BasicPlaceholder
      imgSrc="/images/illustrations/nodata.svg"
      imgAlt="Image presenting no data added yet"
      title={`You've not added any ${dataName} yet`}
      description="Would you like to add one now?"
    >
      {children}
    </BasicPlaceholder>
  );
}

NoDataPlaceholder.propTypes = {
  dataName: PropTypes.string,
  children: PropTypes.node
};
