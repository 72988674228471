import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@chakra-ui/react';

export default function PageHeading({ children, ...other }) {
  return (
    <Heading size="2xl" letterSpacing="tighter" fontWeight="black" {...other}>
      {children}
    </Heading>
  );
}

PageHeading.propTypes = {
  children: PropTypes.node
};
