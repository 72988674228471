const storageKey = 'todos';

export const getTodos = () => {
  const todos = JSON.parse(sessionStorage.getItem(storageKey));

  if (!todos) {
    return [];
  }

  return todos;
};

export const setTodos = todos => {
  sessionStorage.setItem(storageKey, JSON.stringify(todos));
};
