/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  useToast
} from '@chakra-ui/react';

import { GLOBAL_CONSTANTS } from '../../../utils/constants/global.constants';
import { reactHookFormSettings } from '../../../utils/constants/form.constants';
import {
  useCurrenciesState,
  useCurrenciesDispatcher,
  ACTIONS
} from '../../../context/currenciesContext';

import DatePicker from '../../../components/custom/ChakraReactDatePicker/ChakraDatePicker';
import FormLabelExtended from '../../../components/form/elements/FormLabelExtended/FormLabelExtended';
import SubmitButton from '../../../components/form/elements/SubmitButton/SubmitButton';
import CustomNumberInput from '../../../components/form/elements/CustomNumberInput/CustomNumberInput';
import PageHeading from '../../../components/headings/PageHeading/PageHeading';

import {
  errorToast,
  successToast
} from '../../../utils/constants/toast.constants';
import { makeFunctionFailRandomly } from '../../../utils/helpers/common.helpers';
import { useTitle } from '../../../hooks/useTitle';
import {
  getCurrencyFromId,
  prepareCurrencyAfterUserSubmit
} from '../../../services/currencies/currenciesServices';
import CurrencySelect from '../../../components/form/elements/CurrencySelect/CurrencySelect';

const requiredFieldErrorMessage = 'Required Field';

export default function CurrenciesForm({ currencyId, closeModal }) {
  useTitle(`Add new currency - ${process.env.REACT_APP_TITLE}`);

  const toast = useToast();
  const dispatch = useCurrenciesDispatcher();
  const currenciesState = useCurrenciesState();
  const editedCurrency = getCurrencyFromId(currenciesState, currencyId);
  const isEdit = !!currencyId;

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm(reactHookFormSettings);

  const submitHandler = values => {
    const successToastDescription = currencyId =>
      isEdit
        ? `Currency ${currencyId} updated with success!`
        : `New currency pair with id ${currencyId} has been successfully added!`;
    try {
      const currency = makeFunctionFailRandomly(() =>
        prepareCurrencyAfterUserSubmit(values, currencyId)
      );
      dispatch({
        type: isEdit ? ACTIONS.UPDATE : ACTIONS.CREATE,
        payload: currency
      });
      closeModal();
      reset();
      toast(
        successToast(`Success!`, `${successToastDescription(currency.id)}`)
      );
    } catch (e) {
      console.error(e);
      toast(
        errorToast(
          'Unexpected error!',
          'Something went wrong. Please try again!'
        )
      );
    }
  };

  const elements = useRef([
    {
      name: 'from',
      component: CurrencySelect,
      props: {
        controller: Controller,
        control: control,
        rules: {
          required: requiredFieldErrorMessage,
          validate: ({ value }) =>
            value !== getValues('to')?.value ||
            'From currency  cannot be the same as to currency'
        },
        defaultValue: editedCurrency?.from
      },
      isRequired: true,
      label: 'From'
    },
    {
      name: 'to',
      component: CurrencySelect,
      props: {
        controller: Controller,
        control: control,
        rules: {
          required: requiredFieldErrorMessage
        },
        defaultValue: editedCurrency?.to
      },
      isRequired: true,
      label: 'To'
    },
    {
      name: 'exchangeRate',
      component: CustomNumberInput,
      props: {
        register: register,
        rules: { required: requiredFieldErrorMessage },
        defaultValue: editedCurrency?.exchangeRate
      },
      isRequired: true,
      label: 'Exchange Rate'
    },
    {
      name: 'validDate',
      component: DatePicker,
      props: {
        controller: Controller,
        control: control,
        trigger: trigger,
        rules: { required: requiredFieldErrorMessage },
        disabledKeyboardNavigation: true,
        dateFormat: GLOBAL_CONSTANTS.DATE_FORMAT,
        defaultValue: editedCurrency?.validDate,
        placeholderText: 'Select currency date'
      },
      isRequired: true,
      label: 'Valid when?'
    }
  ]);

  return (
    <Flex px="2" py="2" direction="column">
      <PageHeading my="8" textAlign="center">
        {isEdit ? 'Edit currency' : 'Add new currency'}
      </PageHeading>
      <chakra.form onSubmit={handleSubmit(submitHandler)} px={2} py={2}>
        <Stack direction="column" spacing={6}>
          {elements.current.map(
            ({ name, component: Component, props = {}, isRequired, label }) => {
              return (
                <FormControl isInvalid={errors[name]} key={name}>
                  <FormLabelExtended isRequired={isRequired}>
                    {label}
                  </FormLabelExtended>
                  <Component name={name} {...props} />
                  <FormErrorMessage>
                    {errors[name] && errors[name].message}
                  </FormErrorMessage>
                </FormControl>
              );
            }
          )}
          <SubmitButton
            isLoading={isSubmitting}
            loadingText={isEdit ? 'Editing...' : 'Submitting...'}
            onSubmit={handleSubmit(submitHandler)}
          >
            {isEdit ? 'Edit' : 'Add'}
          </SubmitButton>
        </Stack>
      </chakra.form>
    </Flex>
  );
}

export const MemoizedCurrenciesForm = React.memo(CurrenciesForm);

CurrenciesForm.propTypes = {
  id: PropTypes.string,
  closeModal: PropTypes.func
};
