import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Icon } from '@chakra-ui/react';

import { useHistory } from 'react-router';

import { LAYOUT_CONSTANTS } from '../../../../../utils/constants/layout.constants';

import LinkExtended from '../../../../extended/LinkExtended/LinkExtended';

export default function SideBarItem({ icon, children, path }) {
  const history = useHistory();
  const isActive =
    history.location.pathname === path ||
    history.location.pathname === `${path}/`;
  return (
    <LinkExtended w="100%" decorate={false} to={path}>
      <Flex
        mt={2}
        py={3}
        pl={LAYOUT_CONSTANTS.SIDEBAR.ITEMS_PL}
        align="center"
        role="group"
        backgroundColor={isActive && '#1A23'}
        _hover={{ backgroundColor: '#1A23' }}
        transition="all .3s"
        fontSize="lg"
        fontWeight="semibold"
        color="black"
      >
        <Icon as={icon} mr={6} fontSize={LAYOUT_CONSTANTS.NAV_ICON_SIZE} />
        {children}
      </Flex>
    </LinkExtended>
  );
}

SideBarItem.propTypes = {
  icon: PropTypes.elementType,
  children: PropTypes.node,
  path: PropTypes.string
};
