import { v4 as uuidv4 } from 'uuid';
import { checkEmailAlreadyTaken } from '../../../services/auth/authServices';
import { validation } from '../../../utils/constants/form.constants';

import InputExtended from '../../../components/form/elements/InputExtended/InputExtended';
import CheckboxExtended from '../../../components/form/elements/CheckboxExtended/CheckboxExtended';

const REQUIRED_MESSAGE_PART = 'is required';

export function getSignUpFormControls({
  register = () => {},
  getValues = () => {},
  isSubmitting = false
}) {
  return [
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'text',
        disabled: isSubmitting,
        validation: {
          required: `First name ${REQUIRED_MESSAGE_PART}`
        }
      },
      label: 'First Name',
      name: 'firstName',
      id: uuidv4(),
      required: true
    },
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'text',
        disabled: isSubmitting,
        validation: { required: `Last name ${REQUIRED_MESSAGE_PART}` }
      },
      label: 'Last Name',
      name: 'lastName',
      id: uuidv4(),
      required: true
    },
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'email',
        disabled: isSubmitting,
        validation: {
          required: `Email ${REQUIRED_MESSAGE_PART}`,
          validate: value =>
            !checkEmailAlreadyTaken(value) || 'Email address is already taken',
          pattern: {
            value: validation.email.pattern,
            message: validation.email.message
          }
        }
      },
      label: 'Email address',
      name: 'email',
      id: uuidv4(),
      required: true
    },
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'password',
        disabled: isSubmitting,
        validation: {
          required: `Password ${REQUIRED_MESSAGE_PART}`,
          pattern: {
            value: validation.password.pattern,
            message: validation.password.message
          }
        }
      },
      label: 'Password',
      name: 'password',
      id: uuidv4(),
      required: true
    },
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'password',
        disabled: isSubmitting,
        validation: {
          required: `Confirm password ${REQUIRED_MESSAGE_PART}`,
          pattern: {
            value: validation.password.pattern,
            message: validation.password.message
          },
          validate: value =>
            value === getValues('password') || 'Passwords do not match'
        }
      },
      label: 'Confirm password',
      name: 'passwordRepeat',
      id: uuidv4(),
      required: true
    },
    {
      Component: CheckboxExtended,
      componentProps: {
        register,
        disabled: isSubmitting,
        validation: { required: `Agreement ${REQUIRED_MESSAGE_PART}` },
        children: 'I agree with Terms of Service and Privacy Policy'
      },
      name: 'consent',
      id: uuidv4(),
      required: true
    }
  ];
}
