import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@chakra-ui/react';

export default function InputExtended({
  size = 'lg',
  register = () => {},
  validation = {},
  name,
  ...other
}) {
  return (
    <Input
      {...register(name, validation)}
      {...other}
      size={size}
      borderWidth="2px"
      borderColor="gray.300"
    />
  );
}

InputExtended.propTypes = {
  size: PropTypes.string,
  register: PropTypes.func,
  validation: PropTypes.object,
  name: PropTypes.string
};
