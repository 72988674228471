import React, { useState, useRef } from 'react';
import { copyObject } from '../../utils/helpers/common.helpers';
import {
  Flex,
  chakra,
  FormControl,
  Stack,
  Heading,
  Text,
  Checkbox,
  IconButton,
  ScaleFade,
  Image
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { reactHookFormSettings } from '../../utils/constants/form.constants';
import PageWithSidebar from '../../components/layout/PageWithSidebar/PageWithSidebar';
import PageHeading from '../../components/headings/PageHeading/PageHeading';
import InputExtended from '../../components/form/elements/InputExtended/InputExtended';
import FormLabelExtended from '../../components/form/elements/FormLabelExtended/FormLabelExtended';
import SubmitButton from '../../components/form/elements/SubmitButton/SubmitButton';
import TooltipExtended from '../../components/extended/TooltipExtended/TooltipExtended';
import { useTitle } from '../../hooks/useTitle';
import { useTodos } from '../../hooks/useTodos';

import { FiX } from 'react-icons/fi';

export default function ToDoPage() {
  useTitle(`To Do Planner - ${process.env.REACT_APP_TITLE}`);
  const [tasks, setTasks] = useTodos();
  const taskId = useRef(1);
  const { handleSubmit, register, reset } = useForm(reactHookFormSettings);

  const submitHandler = values => {
    const valuesCopy = copyObject(values);
    valuesCopy.id = taskId.current;
    valuesCopy.isCompleted = false;
    valuesCopy.completionDate = null;
    increaseTaskId();
    setTasks(oldValue => [valuesCopy, ...oldValue]);
    reset();
  };

  const increaseTaskId = () => {
    taskId.current++;
  };

  const handleDoneCheckboxClick = (e, id) => {
    const isChecked = e.target.checked;
    const modifiedTasks = tasks
      .map(task => {
        if (task.id === id) {
          task.isCompleted = isChecked;
          if (isChecked) {
            task.completionDate = new Date();
          } else {
            task.completionDate = null;
          }
        }
        return task;
      })
      .sort(
        (x, y) =>
          x.isCompleted - y.isCompleted ||
          x.completionDate - y.completionDate ||
          y.id - x.id
      );
    setTasks(modifiedTasks);
  };

  const handleRemoveTaskClick = id => {
    const newTasks = tasks.filter(task => task.id !== id);

    setTasks(newTasks);
  };

  return (
    <PageWithSidebar px="10" display="flex" flexDirection="column">
      <PageHeading py="12">To-Do Planner</PageHeading>
      <Flex
        backgroundColor="white"
        borderRadius="lg"
        direction="column"
        borderTopWidth="6px"
        borderTopColor="blue.600"
        pt="8"
        pb="8"
        px="10"
        maxWidth={{ base: '55vw', '2xl': '35vw' }}
        maxH="80vh"
        overflowY="auto"
      >
        <chakra.form onSubmit={handleSubmit(submitHandler)}>
          <FormControl w={{ base: '50vw', '2xl': '30vw' }}>
            <FormLabelExtended fontSize="2xl" textAlign="left" pb="4">
              What are you planning?
            </FormLabelExtended>
            <Stack direction="row" spacing="2">
              <InputExtended
                register={register}
                validation={{ required: 'Task name is required' }}
                name="taskName"
                placeholder="Clean the dishes..."
              />
              <SubmitButton>Add Task</SubmitButton>
            </Stack>
          </FormControl>
        </chakra.form>
        {tasks.length === 0 ? (
          <Stack align="center" pt="12" spacing="8">
            <Image src="/images/illustrations/no_tasks.svg" maxW="50%" />
          </Stack>
        ) : (
          <Heading
            fontSize="2xl"
            fontWeight="bold"
            letterSpacing="tight"
            mt="12"
            mb="6"
          >
            My Tasks:
          </Heading>
        )}
        {tasks.map(task => {
          return (
            <ScaleFade key={task.id} initialScale={0.9} in={true}>
              <Stack
                px="4"
                py="4"
                direction="row"
                align="center"
                justify="space-between"
                maxW="80%"
                borderRadius="2xl"
                border="2px"
                borderColor={task.isCompleted ? 'green.400' : 'gray.300'}
                boxShadow="md"
                mb="6"
              >
                <Stack direction="row" align="center" spacing="2">
                  <TooltipExtended label="Remove task">
                    <IconButton
                      onClick={() => handleRemoveTaskClick(task.id)}
                      icon={<FiX />}
                      size="md"
                      background="none"
                      variant="ghost"
                      colorScheme="black"
                      isRound
                    />
                  </TooltipExtended>
                  <Text
                    textDecoration={task.isCompleted ? 'line-through' : 'none'}
                    color={task.isCompleted ? 'gray.400' : 'black'}
                    fontSize="lg"
                  >
                    {task.taskName}
                  </Text>
                </Stack>
                <Checkbox
                  isChecked={task.isCompleted}
                  onChange={e => handleDoneCheckboxClick(e, task.id)}
                  colorScheme="green"
                  size="lg"
                  borderColor="gray.400"
                />
              </Stack>
            </ScaleFade>
          );
        })}
      </Flex>
    </PageWithSidebar>
  );
}
