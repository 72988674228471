import React from 'react';

import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';

import { useTitle } from '../../../hooks/useTitle';

import AuthPage from '../../../components/form/auth/AuthPage/AuthPage';

export default function ForgotPasswordPage() {
  useTitle(`Forgot Password - ${process.env.REACT_APP_TITLE}`);

  return <AuthPage form={ForgotPasswordForm} />;
}
