import React from 'react';
import PropTypes from 'prop-types';

import { FormLabel, chakra } from '@chakra-ui/react';

export default function FormLabelExtended({
  children,
  isRequired = false,
  ...other
}) {
  return (
    <FormLabel {...other} fontWeight="semibold">
      {children}
      {isRequired && (
        <chakra.span ml={1} color="red.500">
          *
        </chakra.span>
      )}
    </FormLabel>
  );
}

FormLabelExtended.propTypes = {
  children: PropTypes.node,
  isRequired: PropTypes.bool
};
