import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Stack,
  FormControl,
  useToast,
  FormErrorMessage
} from '@chakra-ui/react';
import { useHistory } from 'react-router';

import AuthForm from '../../../components/form/auth/AuthForm/AuthForm';
import AuthHeading from '../../../components/form/auth/AuthHeading/AuthHeading';
import AuthSubmitButton from '../../../components/form/auth/AuthSubmitButton/AuthSubmitButton';
import FormLabelExtended from '../../../components/form/elements/FormLabelExtended/FormLabelExtended';

import { createUser } from '../../../services/auth/authServices';
import { reactHookFormSettings } from '../../../utils/constants/form.constants';
import {
  errorToast,
  successToast
} from '../../../utils/constants/toast.constants';

import { delayRedirect } from '../../../utils/helpers/common.helpers';
import { getSignUpFormControls } from './formControls';
import { useUser } from '../../../context/userContext';

export default function SignUpForm() {
  const history = useHistory();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors, isSubmitting }
  } = useForm(reactHookFormSettings);
  const [formControls] = useState(() =>
    getSignUpFormControls({ register, getValues, isSubmitting })
  );

  const { setUser } = useUser();

  const onSubmit = async ({ firstName, lastName, email, password }) => {
    try {
      const user = await createUser({
        firstName,
        lastName,
        email,
        password
      });
      toast(
        successToast(
          'Account created.',
          "Your account was successfully created. You'll be soon redirected to Dashboard."
        )
      );
      delayRedirect(() => {
        reset();
        setUser(user);
        history.push('/');
      });
    } catch (err) {
      toast(errorToast('Failed to create account', err));
    }
  };

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={8}>
        <AuthHeading>Create Account</AuthHeading>
        <Stack direction="column" spacing={4}>
          {formControls.map(control => (
            <FormControl key={control.id} isInvalid={errors[control.name]}>
              {control.label && (
                <FormLabelExtended
                  isRequired={control.required}
                  htmlFor={control.id}
                >
                  {control.label}
                </FormLabelExtended>
              )}
              <control.Component
                name={control.name}
                id={control.id}
                {...control.componentProps}
              />
              <FormErrorMessage>
                {errors[control.name] && errors[control.name].message}
              </FormErrorMessage>
            </FormControl>
          ))}
        </Stack>
      </Stack>
      <AuthSubmitButton
        mt={6}
        isLoading={isSubmitting}
        loadingText="Creating account..."
        spinnerPlacement="end"
        onSubmit={handleSubmit(onSubmit)}
      >
        Create Account
      </AuthSubmitButton>
    </AuthForm>
  );
}
