import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@chakra-ui/react';

import { LAYOUT_CONSTANTS } from '../../../../../utils/constants/layout.constants';

export default function CategoryName({ name }) {
  return (
    <Heading
      mt={8}
      mb={3}
      w="100%"
      textTransform="uppercase"
      letterSpacing="1px"
      as="h6"
      size="xs"
      color="gray.700"
      opacity=".5"
      pl={LAYOUT_CONSTANTS.SIDEBAR.ITEMS_PL}
    >
      {name}
    </Heading>
  );
}

CategoryName.propTypes = {
  name: PropTypes.string
};
