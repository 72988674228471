import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import CountryWithFlag from '../CountryWithFlag/CountryWithFlag';

const { Option } = components;

export default function CountrySelectOption(props) {
  const { data, isFocused, isSelected } = props;
  return (
    <Option {...props}>
      <CountryWithFlag
        flagImage={`/images/flags/${data.cc1}.svg`}
        text={data.label}
        backgroundColor={
          isSelected ? 'blue.200' : isFocused ? 'gray.200' : null
        }
        fontWeight={isSelected && 'bold'}
        color={isSelected && 'gray.800'}
        cursor="pointer"
        px="2"
        py="2"
      />
    </Option>
  );
}

CountrySelectOption.propTypes = {
  data: PropTypes.object,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};
