import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@chakra-ui/react';

export default function TooltipExtended({ label, children, ...other }) {
  return (
    <Tooltip
      {...other}
      hasArrow
      label={label}
      fontSize="sm"
      closeOnMouseDown={true}
      bg="gray.500"
    >
      {children}
    </Tooltip>
  );
}

TooltipExtended.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node
};
