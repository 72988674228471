import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Circle, chakra, Box } from '@chakra-ui/react';

const NOTIFICATION_CIRCLE_SIZE = {
  SMALL: 20,
  MEDIUM: 22,
  LARGE: 25
};

function NotificationWithCount({ count }) {
  const getCircleSize = count => {
    if (count > 99) {
      return NOTIFICATION_CIRCLE_SIZE.LARGE;
    } else if (count > 9) {
      return NOTIFICATION_CIRCLE_SIZE.MEDIUM;
    }
    return NOTIFICATION_CIRCLE_SIZE.SMALL;
  };

  const circleSize = getCircleSize(count);
  return (
    <Circle
      size={`${circleSize}px`}
      bg="red.500"
      color="white"
      fontSize="xs"
      fontWeight="bold"
      position="absolute"
      top={circleSize > NOTIFICATION_CIRCLE_SIZE.MEDIUM ? '-2px' : '1px'}
      right="3px"
    >
      <chakra.span>{count >= 100 ? '99+' : count}</chakra.span>
    </Circle>
  );
}

const IconButtonExtended = React.forwardRef(
  ({ icon, notificationCount, ...other }, ref) => {
    return (
      <Box position="relative">
        <IconButton {...other} ref={ref} icon={icon} variant="ghost" isRound />
        {notificationCount > 0 ? (
          <NotificationWithCount count={notificationCount} />
        ) : null}
      </Box>
    );
  }
);

// making sure that eslint is not throwing display-name rule when using forward ref
IconButtonExtended.displayName = 'IconButtonExtended';

export default IconButtonExtended;

NotificationWithCount.propTypes = {
  count: PropTypes.number
};

IconButtonExtended.propTypes = {
  icon: PropTypes.element,
  notificationCount: PropTypes.number
};
