import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from '../BaseDialog/BaseDialog';
import { Button, Stack } from '@chakra-ui/react';
import ConfirmAlert from '../ConfirmAlert/ConfirmAlert';

const ConfirmButtons = ({ onCancel, onConfirm, confirmButtonText }) => {
  return (
    <Stack direction="row" spacing="4">
      <Button
        onClick={onCancel}
        type="button"
        variant="solid"
        colorScheme="gray"
        p="6"
      >
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        type="button"
        variant="solid"
        colorScheme="blue"
        p="6"
      >
        {confirmButtonText}
      </Button>
    </Stack>
  );
};

export default function ConfirmDialog({
  isOpen,
  onConfirm,
  onCancel,
  title,
  description,
  confirmButtonText
}) {
  return (
    <BaseDialog
      body={<ConfirmAlert title={title} description={description} />}
      footer={
        <ConfirmButtons
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmButtonText={confirmButtonText}
        />
      }
      isOpen={isOpen}
      onClose={onCancel}
    />
  );
}

ConfirmButtons.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmButtonText: PropTypes.string
};

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string
};
