import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import CountrySelectOption from '../CountrySelectOption/CountrySelectOption';
import CountrySelectValue from '../CountrySelectValue/CountrySelectValue';
import Select from '../../../custom/ChakraReactSelect/ChakraReactSelect';

import {
  getCurrencies,
  getCurrenciesOptionsData
} from '../../../../services/currencies/currenciesServices';

const currencySelectComponent = {
  Option: CountrySelectOption,
  SingleValue: CountrySelectValue
};

function CurrencySelect({ size = 'lg', isSearchable = true, ...other }) {
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const prepareCurrenciesOptions = async () => {
      try {
        setIsLoading(true);
        const currencies = await (await getCurrencies()).json();
        // Currencies might still be pending when component is already unmounted
        // This is to prevent the state updating on already unmounted component
        if (isMounted) {
          const preparedData = getCurrenciesOptionsData(currencies);
          setCurrenciesOptions(preparedData);
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    prepareCurrenciesOptions();
    return () => {
      isMounted = false;
      setIsLoading(false);
    };
  }, []);

  return (
    <Select
      {...other}
      size={size}
      isSearchable={isSearchable}
      isClearable
      placeholder={isLoading ? 'Loading currencies...' : 'Select currency...'}
      options={currenciesOptions}
      components={currencySelectComponent}
      isLoading={isLoading}
      isDisabled={isLoading}
      openMenuOnFocus={true}
    />
  );
}

const CurrencySelectRef = React.forwardRef((props, _) => (
  <CurrencySelect {...props} />
));

export default function CurrencySelectController({
  controller: Controller,
  control,
  name = '',
  defaultValue = '',
  rules = {},
  ...other
}) {
  return (
    <Controller
      {...other}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => <CurrencySelectRef {...field} />}
    />
  );
}

CurrencySelectRef.displayName = 'CurrencySelect';

CurrencySelect.propTypes = {
  size: PropTypes.string,
  isSearchable: PropTypes.bool,
  ref: PropTypes.func
};

CurrencySelectController.propTypes = {
  controller: PropTypes.func,
  control: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rules: PropTypes.object
};
