import React from 'react';

import SignUpForm from '../SignUpForm/SignUpForm';

import AuthPage from '../../../components/form/auth/AuthPage/AuthPage';

import { useTitle } from '../../../hooks/useTitle';

export default function SignUpPage() {
  useTitle(`Sign up - ${process.env.REACT_APP_TITLE}`);
  return <AuthPage form={SignUpForm} />;
}
