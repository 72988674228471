import { v4 as uuidv4 } from 'uuid';
import { validation } from '../../../utils/constants/form.constants';
import InputExtended from '../../../components/form/elements/InputExtended/InputExtended';

const REQUIRED_MESSAGE_PART = 'is required';

export function getLoginFormControls(
  register = () => {},
  isSubmitting = false
) {
  return [
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'email',
        disabled: isSubmitting,
        validation: {
          required: `Email ${REQUIRED_MESSAGE_PART}`,
          pattern: {
            value: validation.email.pattern,
            message: validation.email.message
          }
        }
      },
      label: 'Email address',
      name: 'email',
      id: uuidv4(),
      required: true
    },
    {
      Component: InputExtended,
      componentProps: {
        register: register,
        type: 'password',
        disabled: isSubmitting,
        validation: {
          required: `Password ${REQUIRED_MESSAGE_PART}`
        }
      },
      label: 'Password',
      name: 'password',
      id: uuidv4(),
      required: true
    }
  ];
}
