import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { getLoggedInUser } from '../services/auth/authServices';

const UserContext = createContext();

function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(getLoggedInUser());

  return (
    <UserContext.Provider
      value={{ user: currentUser, setUser: setCurrentUser }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const user = useContext(UserContext);
  if (typeof user === undefined) {
    throw new Error('useUser must be used within UserContext');
  }
  return user;
}

export { UserProvider, useUser };

UserProvider.propTypes = {
  children: PropTypes.node
};
