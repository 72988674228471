import React from 'react';
import PropTypes from 'prop-types';

import { FiChevronDown } from 'react-icons/fi';

import {
  HStack,
  Avatar as ChakraAvatar,
  Flex,
  Text,
  Icon
} from '@chakra-ui/react';

function AvatarName({ username }) {
  return (
    <Flex alignItems="center">
      <Text>{username}</Text>
      <Icon ml={2} as={FiChevronDown} />
    </Flex>
  );
}

function Avatar({ img, ...other }) {
  return <ChakraAvatar {...other} size="md" src={img} bg="blackAlpha.300" />;
}

export default function AvatarExtended({ img, username, ...other }) {
  return username ? (
    <HStack spacing={3}>
      <Avatar img={img} />
      <Flex alignItems="center">
        <Text>{username}</Text>
        <Icon ml={2} as={FiChevronDown} mr={3} />
      </Flex>
    </HStack>
  ) : (
    <Avatar img={img} mr={3} />
  );
}

Avatar.propTypes = {
  img: PropTypes.string
};

AvatarName.propTypes = {
  username: PropTypes.string
};

AvatarExtended.propTypes = {
  img: PropTypes.string,
  username: PropTypes.string
};
