import React, { useState } from 'react';

import { useHistory } from 'react-router';

import {
  FormControl,
  Stack,
  Text,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';

import AuthForm from '../../../components/form/auth/AuthForm/AuthForm';
import AuthSubmitButton from '../../../components/form/auth/AuthSubmitButton/AuthSubmitButton';
import AuthHeading from '../../../components/form/auth/AuthHeading/AuthHeading';
import FormLabelExtended from '../../../components/form/elements/FormLabelExtended/FormLabelExtended';
import LinkExtended from '../../../components/extended/LinkExtended/LinkExtended';
import CheckboxExtended from '../../../components/form/elements/CheckboxExtended/CheckboxExtended';

import { authenticateUser } from '../../../services/auth/authServices';

import { STYLE_CONSTANTS } from '../../../utils/constants/style.constants';

import { ROUTES } from '../../../routes/routes';

import { delayRedirect } from '../../../utils/helpers/common.helpers';

import {
  successToast,
  errorToast
} from '../../../utils/constants/toast.constants';
import { reactHookFormSettings } from '../../../utils/constants/form.constants';
import { getLoginFormControls } from './formControls';
import { useUser } from '../../../context/userContext';

export default function LoginForm() {
  const { setUser } = useUser();
  const history = useHistory();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting }
  } = useForm(reactHookFormSettings);
  const [formControls] = useState(() =>
    getLoginFormControls(register, isSubmitting)
  );

  const onSubmit = async ({ email, password, rememberMe }) => {
    try {
      const authenticatedUser = await authenticateUser({
        email,
        password,
        rememberMe
      });
      toast(
        successToast(
          'Authenticated',
          "Logged in. You'll be soon redirect to home"
        )
      );
      delayRedirect(() => {
        reset();
        setUser(authenticatedUser);
        history.push('/');
      });
    } catch (err) {
      toast(errorToast('Authentication failed', err));
    }
  };

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" align="center" spacing={6} mb={6}>
        <AuthHeading>Sign in to your account</AuthHeading>
        <Text>
          Don&apos;t have an account?{' '}
          <LinkExtended to={ROUTES.AUTH.SIGN_UP}>Create one here!</LinkExtended>
        </Text>
      </Stack>
      <Stack direction="column" spacing={4}>
        {formControls.map(control => (
          <FormControl key={control.id} isInvalid={errors[control.name]}>
            {control.label && (
              <FormLabelExtended
                isRequired={control.required}
                htmlFor={control.id}
              >
                {control.label}
              </FormLabelExtended>
            )}
            <control.Component
              name={control.name}
              id={control.id}
              {...control.componentProps}
            />
            <FormErrorMessage>
              {errors[control.name] && errors[control.name].message}
            </FormErrorMessage>
          </FormControl>
        ))}
        <Stack direction="column" spacing={10}>
          <Stack direction="row" justifyContent="space-between">
            <CheckboxExtended
              register={register}
              name="rememberMe"
              colorScheme={STYLE_CONSTANTS.DEFAULT_COLOR_SCHEME}
              size="md"
            >
              Remember me
            </CheckboxExtended>
            <LinkExtended to={ROUTES.AUTH.FORGOT_PASSWORD}>
              Forgot Password?
            </LinkExtended>
          </Stack>
          <AuthSubmitButton
            isLoading={isSubmitting}
            loadingText="Signing in..."
            spinnerPlacement="end"
            onSubmit={handleSubmit(onSubmit)}
          >
            Sign in
          </AuthSubmitButton>
        </Stack>
      </Stack>
    </AuthForm>
  );
}
