import { extendTheme } from '@chakra-ui/react';

const defaultBorderStyle = {
  border: '2px solid',
  borderColor: 'gray.300',
  _hover: {
    borderColor: 'gray.400'
  }
};

const defaultFormElementSize = 'lg';

const theme = extendTheme({
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            ...defaultBorderStyle
          }
        }
      },
      defaultProps: {
        size: defaultFormElementSize
      }
    },
    NumberInput: {
      variants: {
        outline: {
          field: {
            ...defaultBorderStyle
          }
        }
      },
      defaultProps: {
        size: defaultFormElementSize
      }
    }
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif'
  }
});

export default theme;
