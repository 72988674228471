import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@chakra-ui/react';

export default function MenuItemExtended({ children, ...other }) {
  return (
    <MenuItem py={3} _focus={{ backgroundColor: 'blue.50' }} {...other}>
      {children}
    </MenuItem>
  );
}

MenuItemExtended.propTypes = {
  children: PropTypes.node
};
