export const STYLE_CONSTANTS = {
  LINK_COLOR: 'blue.500',
  DEFAULT_COLOR_SCHEME: 'blue',
  BG_GRADIENT: 'linear(to-r, rgba(239,249,249,1) , rgba(182,199,226,1))',
  NAVBAR: {
    USER: {
      ICON_SIZE: '20px'
    }
  }
};
