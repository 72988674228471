const authRouteStart = 'auth';

export const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: `/${authRouteStart}/login`,
    SIGN_UP: `/${authRouteStart}/sign-up`,
    FORGOT_PASSWORD: `/${authRouteStart}/forgot-password`
  },
  SALES: {
    CONTACTS: `/contacts`,
    CURRENCIES: `/currencies`
  },
  HR: {
    EMPLOYEES: '/employees'
  },
  TASK_MANAGEMENT: {
    TO_DO: '/todo'
  }
};
