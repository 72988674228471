import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Image, chakra } from '@chakra-ui/react';

export default function CountryWithFlag({ flagImage, text, ...other }) {
  return (
    <Stack direction="row" align="center" spacing="2" {...other}>
      <Image src={flagImage} height="12px" loading="lazy" boxShadow="md" />
      <chakra.span>{text}</chakra.span>
    </Stack>
  );
}

export const CountryWithFlagMemo = React.memo(CountryWithFlag);

CountryWithFlag.propTypes = {
  flagImage: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
