import { v4 as uuidv4 } from 'uuid';
import { makeMethodAsync } from '../../utils/helpers/common.helpers';

const loggedInUserStorageKey = 'loggedInUser';
const usersStorageKey = 'users';

export const checkEmailAlreadyTaken = email => {
  const users = getUsers();

  if (!users) {
    return false;
  }
  return users.some(user => user.email === email);
};

export const logOutUser = () => {
  localStorage.removeItem(loggedInUserStorageKey);
  sessionStorage.removeItem(loggedInUserStorageKey);
};

export const getLoggedInUser = () => {
  let loggedInUser = JSON.parse(localStorage.getItem(loggedInUserStorageKey));

  if (!loggedInUser) {
    loggedInUser = JSON.parse(sessionStorage.getItem(loggedInUserStorageKey));
  }

  return loggedInUser ? loggedInUser : undefined;
};

export const getUsers = () => {
  return JSON.parse(localStorage.getItem(usersStorageKey));
};

export const authenticateUser = ({ email, password, rememberMe = false }) => {
  const users = getUsers();

  const filteredUser = users?.filter(
    user => user.email === email && user.password === password
  )[0];

  if (filteredUser) {
    filteredUser.rememberMe = rememberMe;
  }

  const resolveCallback = resolve => {
    if (rememberMe) {
      localStorage.setItem(
        loggedInUserStorageKey,
        JSON.stringify(filteredUser)
      );
    } else {
      sessionStorage.setItem(
        loggedInUserStorageKey,
        JSON.stringify(filteredUser)
      );
    }

    resolve(filteredUser);
  };

  return makeMethodAsync({
    resolveCallback: resolveCallback,
    rejectReturn: 'User or password is incorrect',
    conditionToCheckToResolve: filteredUser?.email ? true : false
  });
};

export const createUser = ({ firstName, lastName = '', email, password }) => {
  const newUser = {
    id: uuidv4(),
    firstName,
    lastName,
    email,
    password
  };

  let users = getUsers();

  if (users) {
    users.push(newUser);
  } else {
    users = [newUser];
  }

  const resolveCallback = resolve => {
    localStorage.setItem(usersStorageKey, JSON.stringify(users));
    sessionStorage.setItem(loggedInUserStorageKey, JSON.stringify(newUser));
    resolve(newUser);
  };

  return makeMethodAsync({
    resolveCallback: resolveCallback,
    rejectReturn:
      'Ooops! 🙈 Our service is currently unavailable, please try again later'
  });
};
