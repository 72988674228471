import React from 'react';
import LoginForm from '../LoginForm/LoginForm';

import AuthPage from '../../../components/form/auth/AuthPage/AuthPage';
import { useTitle } from '../../../hooks/useTitle';

export default function LoginPage() {
  useTitle(`Sign In - ${process.env.REACT_APP_TITLE}`);

  return <AuthPage form={LoginForm} />;
}
