import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from '../../../routes/routes';

import { useUser } from '../../../context/userContext';

export default function PublicRoute({ component: Component, ...other }) {
  const { user } = useUser();

  return (
    <Route
      {...other}
      render={props => {
        if (user?.email) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.HOME,
                state: { from: props.location }
              }}
            />
          );
        }
        return <Component {...other} {...props} />;
      }}
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object
};
