import React, { useState } from 'react';

import IconButtonExtended from '../../../../extended/IconButtonExtended/IconButtonExtended';
import TooltipExtended from '../../../../extended/TooltipExtended/TooltipExtended';
import Notification from '../Notification/Notification';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuDivider,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';

import { LAYOUT_CONSTANTS } from '../../../../../utils/constants/layout.constants';

const fakeNotifications = [
  {
    id: 1,
    title: 'Your financial report is overdue',
    description: 'Please submit your yearly figures for 2020 by October 15th',
    date: 'Just now'
  },
  {
    id: 2,
    title: 'External budget was approved',
    description: 'Your external staffing request has been approved',
    date: '5 hours ago'
  },
  {
    id: 3,
    title: 'Leave request',
    description:
      'John Doe has requested vacation from August 15 - Sep 10, 2020',
    date: '11 hours ago'
  }
];

const ZeroNotifications = () => (
  <Alert status="success">
    <AlertIcon />
    <Box flex="1">
      <AlertTitle>Success</AlertTitle>
      <AlertDescription display="block">No new notifications!</AlertDescription>
    </Box>
  </Alert>
);

export default function Notifications() {
  const [notifications, setNotifications] = useState(fakeNotifications);
  return (
    <Menu>
      <TooltipExtended label="Notifications">
        <MenuButton
          as={IconButtonExtended}
          icon={<FiBell />}
          notificationCount={notifications?.length}
          fontSize={LAYOUT_CONSTANTS.NAV_ICON_SIZE}
          size="lg"
          aria-label="Open Notifications"
        />
      </TooltipExtended>
      <MenuList pb={0} w="lg">
        <MenuGroup
          py="2"
          title="Notifications"
          fontSize="xl"
          fontWeight="bold"
          letterSpacing="tight"
        >
          <MenuDivider orientation="horizontal" mb={0} />
        </MenuGroup>
        {notifications.length > 0 ? (
          notifications.map(({ id, title, description, date }, index) => (
            <Notification
              key={id}
              title={title}
              description={description}
              date={date}
              isLast={index === notifications.length - 1}
              id={id}
              setNotifications={setNotifications}
            />
          ))
        ) : (
          <ZeroNotifications />
        )}
      </MenuList>
    </Menu>
  );
}
