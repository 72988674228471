import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@chakra-ui/react';

export default function FullPage({ children, ...other }) {
  return (
    <Flex {...other} minH="100vh" align="center">
      {children}
    </Flex>
  );
}

FullPage.propTypes = {
  children: PropTypes.node
};
