import './App.css';

import SideBar from './components/layout/navigation/SideBar/SideBar';
import NavBar from './components/layout/navigation/NavBar/NavBar';

import LoginPage from './views/Login/LoginPage/LoginPage';
import SignUpPage from './views/SignUp/SignUpPage/SignUpPage';
import CurrenciesPage from './views/Currencies/CurrenciesPage/CurrenciesPage';
import ToDoPage from './views/ToDo/ToDoPage';
import ForgotPasswordPage from './views/ForgotPassword/ForgotPasswordPage/ForgotPasswordPage';
import PublicRoute from './components/routing/PublicRoute/PublicRoute';
import PrivateRoute from './components/routing/PrivateRoute/PrivateRoute';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ROUTES } from './routes/routes';

import { CurrenciesProvider } from './context/currenciesContext';

import { Box } from '@chakra-ui/react';

function MainApp() {
  return (
    <>
      <NavBar />
      <SideBar />
      <CurrenciesProvider>
        <Switch>
          <Route
            exact
            path={ROUTES.SALES.CURRENCIES}
            component={CurrenciesPage}
          />
          <Route
            exact
            path={ROUTES.TASK_MANAGEMENT.TO_DO}
            component={ToDoPage}
          />
        </Switch>
      </CurrenciesProvider>
    </>
  );
}

function App() {
  return (
    <Router>
      <Box className="App" minH="100vh" backgroundColor="gray.100">
        <Switch>
          <PublicRoute exact path={ROUTES.AUTH.LOGIN} component={LoginPage} />
          <PublicRoute
            exact
            path={ROUTES.AUTH.SIGN_UP}
            component={SignUpPage}
          />
          <PublicRoute
            exact
            path={ROUTES.AUTH.FORGOT_PASSWORD}
            component={ForgotPasswordPage}
          />
          <PrivateRoute path="/" component={MainApp} />
        </Switch>
      </Box>
    </Router>
  );
}

export default App;
