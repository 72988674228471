import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../../routes/routes';

import { useUser } from '../../../context/userContext';

export default function PrivateRoute({ component: Component, ...other }) {
  const { user } = useUser();

  return (
    <Route
      {...other}
      render={props => {
        if (user?.email) {
          return <Component {...other} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: ROUTES.AUTH.LOGIN,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object
};
