import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const LinkExtended = forwardRef(
  (
    {
      color = 'blue.500',
      as = RouterLink,
      children,
      decorate = true,
      ...other
    },
    ref
  ) => {
    const disableDecorationCSS = !decorate && {
      style: { textDecoration: 'none' }
    };
    return (
      <Link
        ref={ref}
        _focus={{ boxShadow: 'none' }}
        as={as}
        color={color}
        {...disableDecorationCSS}
        {...other}
      >
        {children}
      </Link>
    );
  }
);

export default LinkExtended;

// export default function LinkExtended({
//   color = 'blue.500',
//   as = RouterLink,
//   children,
//   decorate = true,
//   ...other
// }) {
//   const disableDecorationCSS = !decorate && {
//     style: { textDecoration: 'none' }
//   };

//   return (
//     <Link
//       _focus={{ boxShadow: 'none' }}
//       as={as}
//       color={color}
//       {...disableDecorationCSS}
//       {...other}
//     >
//       {children}
//     </Link>
//   );
// }

LinkExtended.propTypes = {
  as: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
  color: PropTypes.string,
  decorate: PropTypes.bool
};
