import React from 'react';
import PropTypes from 'prop-types';

import { Center, Heading } from '@chakra-ui/react';
import LinkExtended from '../../../../extended/LinkExtended/LinkExtended';
import { ROUTES } from '../../../../../routes/routes';

export default function Logo({ text }) {
  return (
    <Center>
      <LinkExtended to={ROUTES.HOME} decorate={false}>
        <Heading
          my={6}
          align="left"
          fontSize="3xl"
          fontWeight="black"
          color="black"
          letterSpacing="tighter"
        >
          {text}
        </Heading>
      </LinkExtended>
    </Center>
  );
}

Logo.propTypes = {
  text: PropTypes.string
};
