import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function BaseModal({ isOpen, onClose, children, ...other }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...other}>
      <ModalOverlay />
      <ModalContent borderTopWidth="5px" borderTopColor="blue.600">
        <ModalCloseButton />
        <ModalBody py="6">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

BaseModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
