import React from 'react';
import PropTypes from 'prop-types';

import { chakra } from '@chakra-ui/react';

export default function AuthForm({ width = 'lg', children, ...other }) {
  return (
    <chakra.form {...other} bg="white" p={8} rounded="lg" w={width}>
      {children}
    </chakra.form>
  );
}

AuthForm.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node
};
