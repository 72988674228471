import React from 'react';
import PropTypes from 'prop-types';
import { LAYOUT_CONSTANTS } from '../../../utils/constants/layout.constants';

import { Box } from '@chakra-ui/react';

export default function PageWithSidebar({ children, ...other }) {
  return (
    <Box ml={LAYOUT_CONSTANTS.SIDEBAR.WIDTH} {...other}>
      {children}
    </Box>
  );
}

PageWithSidebar.propTypes = {
  children: PropTypes.node
};
