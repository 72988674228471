import React from 'react';
import PropTypes from 'prop-types';

import CountryWithFlag from '../CountryWithFlag/CountryWithFlag';

export default function CountrySelectValue({ data }) {
  return (
    <CountryWithFlag
      flagImage={`/images/flags/${data.cc1}.svg`}
      text={`1 ${data.label}`}
    />
  );
}

CountrySelectValue.propTypes = {
  data: PropTypes.object
};
