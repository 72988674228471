export const successToast = (title, description) => ({
  title,
  description,
  status: 'success',
  duration: 3000,
  position: 'top',
  isClosable: true
});

export const errorToast = (title, description) => ({
  title,
  description,
  status: 'error',
  duration: 5000,
  position: 'top',
  isClosable: true
});
