import React from 'react';
import PropTypes from 'prop-types';

import TooltipExtended from '../../../extended/TooltipExtended/TooltipExtended';
import { IconButton } from '@chakra-ui/react';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight
} from 'react-icons/fi';

export const CHANGE_PAGE_DIRECTIONS = {
  FIRST: 'FIRST',
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
  LAST: 'LAST'
};

const getChangePageProps = direction => {
  switch (direction) {
    case CHANGE_PAGE_DIRECTIONS.FIRST: {
      return { changePageIcon: FiChevronsLeft, tooltipLabel: 'First Page' };
    }
    case CHANGE_PAGE_DIRECTIONS.PREVIOUS: {
      return { changePageIcon: FiChevronLeft, tooltipLabel: 'Previous Page' };
    }
    case CHANGE_PAGE_DIRECTIONS.NEXT: {
      return { changePageIcon: FiChevronRight, tooltipLabel: 'Next Page' };
    }
    case CHANGE_PAGE_DIRECTIONS.LAST: {
      return { changePageIcon: FiChevronsRight, tooltipLabel: 'Last Page' };
    }
    default: {
      throw new Error(`Wrong direction type: ${direction}`);
    }
  }
};

export default function ChangePage({
  onClick,
  isDisabled,
  direction,
  ...other
}) {
  const { changePageIcon: ChangePageIcon, tooltipLabel } = getChangePageProps(
    direction
  );
  return (
    <TooltipExtended label={tooltipLabel}>
      <IconButton
        {...other}
        onClick={onClick}
        isDisabled={isDisabled}
        icon={<ChangePageIcon fontSize="20px" />}
      />
    </TooltipExtended>
  );
}

ChangePage.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  direction: PropTypes.string
};
