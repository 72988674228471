import { v4 as uuidv4 } from 'uuid';
import { FiUsers, FiDollarSign, FiCheckSquare } from 'react-icons/fi';
import { ROUTES } from '../../../../routes/routes';

const sales = {
  categoryName: 'sales',
  categoryItems: [
    {
      id: uuidv4(),
      title: 'Currencies',
      icon: FiDollarSign,
      path: ROUTES.SALES.CURRENCIES
    }
  ]
};

const hr = {
  categoryName: 'hr',
  categoryItems: [
    {
      id: uuidv4(),
      title: 'Employees',
      icon: FiUsers,
      path: ROUTES.HR.EMPLOYEES
    }
  ]
};

const taskManagement = {
  categoryName: 'task management',
  categoryItems: [
    {
      id: uuidv4(),
      title: 'To-Do',
      icon: FiCheckSquare,
      path: ROUTES.TASK_MANAGEMENT.TO_DO
    }
  ]
};

export const sideBarItems = [sales, hr, taskManagement];
