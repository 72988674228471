import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@chakra-ui/react';

export default function AuthHeading({ children, ...other }) {
  return (
    <Heading as="h3" size="lg" textAlign="center" {...other}>
      {children}
    </Heading>
  );
}

AuthHeading.propTypes = {
  children: PropTypes.node
};
