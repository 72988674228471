import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function CheckboxExtended({
  register = () => {},
  name = '',
  validation = {},
  children,
  ...other
}) {
  return (
    <Checkbox {...register(name, validation)} {...other}>
      {children}
    </Checkbox>
  );
}

CheckboxExtended.propTypes = {
  register: PropTypes.func,
  validation: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node
};
