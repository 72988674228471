import React from 'react';
import PropTypes from 'prop-types';

import FullPage from '../../../layout/FullPage/FullPage';
import { Stack, Flex } from '@chakra-ui/react';
import Logo from '../Logo/Logo';

export default function AuthPage({ form: Form }) {
  return (
    <FullPage direction="row" justifyContent="center" alignItems="center">
      <Flex
        borderTopLeftRadius="xl"
        borderBottomLeftRadius="xl"
        flexBasis="45%"
        justifyContent="center"
        alignItems="center"
        backgroundColor="white"
        minH="100vh"
      >
        <Form />
      </Flex>
      <Stack
        borderTopRightRadius="xl"
        borderBottomRightRadius="xl"
        align="center"
        justify="center"
        direction="column"
        flexBasis="55%"
        bg="#AABDC5"
        minH="100vh"
        backgroundImage="url('/images/logo/mainBackground.svg')"
        backgroundSize="cover"
      >
        <Logo />
      </Stack>
    </FullPage>
  );
}

AuthPage.propTypes = {
  form: PropTypes.elementType
};
