import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';

export default function AuthSubmitButton({ size = 'lg', children, ...other }) {
  return (
    <Button
      {...other}
      size={size}
      w="full"
      type="submit"
      colorScheme="blue"
      rightIcon={<FiArrowRight />}
    >
      {children}
    </Button>
  );
}

AuthSubmitButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node
};
