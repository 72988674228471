import React from 'react';
import PropTypes from 'prop-types';

import { NumberInput, NumberInputField } from '@chakra-ui/react';

export default function CustomNumberInput({
  defaultValue,
  register,
  name,
  rules,
  ...other
}) {
  return (
    <NumberInput {...other} defaultValue={defaultValue}>
      <NumberInputField {...register(name, rules)} />
    </NumberInput>
  );
}

CustomNumberInput.propTypes = {
  defaultValue: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  rules: PropTypes.object
};
