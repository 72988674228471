import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@chakra-ui/react';

export default function SubmitButton({
  isLoading = false,
  loadingText,
  children,
  ...other
}) {
  return (
    <Button
      {...other}
      isLoading={isLoading}
      loadingText={loadingText}
      spinnerPlacement="end"
      variant="solid"
      type="submit"
      colorScheme="blue"
      size="lg"
    >
      {children}
    </Button>
  );
}

SubmitButton.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};
