/* eslint-disable react/display-name, react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import { FaSortDown, FaSortUp, FaSort } from 'react-icons/fa';

import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  chakra,
  Icon,
  Flex,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Stack,
  Box,
  Checkbox
} from '@chakra-ui/react';

import ChangePage, {
  CHANGE_PAGE_DIRECTIONS
} from '../../navigation/pagination/ChangePage/ChangePage';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          ref={resolvedRef}
          isChecked={checked}
          isIndeterminate={indeterminate}
          size="lg"
          {...rest}
        />
      </>
    );
  }
);

const SortIcon = ({ column }) => {
  if (column.Header === '' || column.disableSortBy) {
    return null;
  }

  if (column.isSorted) {
    if (column.isSortedDesc) {
      return <Icon as={FaSortDown} ml={1} boxSize="4" />;
    } else {
      return <Icon as={FaSortUp} ml={1} boxSize="4" />;
    }
  } else {
    return <Icon as={FaSort} ml={1} boxSize="4" />;
  }
};

const selectionColumn = {
  id: 'selection',
  Header: ({ getToggleAllPageRowsSelectedProps }) => (
    <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
  ),
  Cell: ({ row }) => (
    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  ),
  disableSortBy: true
};

export default function ChakraReactTable({
  columns,
  data,
  caption,
  isSelectable,
  setSelectedIds = () => {},
  ...other
}) {
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        if (isSelectable) {
          return [selectionColumn, ...columns];
        } else {
          return [...columns];
        }
      });
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    selectedFlatRows
  } = tableInstance;

  useDeepCompareEffect(() => {
    const setSelectedRows = () => {
      const selectedRows = selectedFlatRows.map(flatRow => flatRow.original.id);
      setSelectedIds(selectedRows);
    };

    setSelectedRows();
  }, [selectedFlatRows]);

  const handlePageChange = value => {
    const page = value ? value - 1 : 0;
    gotoPage(page);
  };

  const handlePageSizeChange = e => {
    setPageSize(parseInt(e.target.value));
  };

  const PageLocation = () => {
    const total = data.length;
    const rowsLBound = pageIndex * pageSize + 1;
    let rowsUBound = (pageIndex + 1) * pageSize;
    rowsUBound = rowsUBound > total ? total : rowsUBound;

    return (
      <Box flexShrink="0">
        <Text>
          Rows:{' '}
          <Text as="span">
            {rowsLBound} - {rowsUBound}
          </Text>{' '}
          of{' '}
          <Text fontWeight="bold" as="span">
            {total}
          </Text>
        </Text>
      </Box>
    );
  };

  const GoToPage = () => {
    return (
      <Stack spacing="2" direction="row" align="center" flexShrink="0">
        <Text>Go to Page</Text>
        <NumberInput
          w="24"
          min={1}
          max={pageOptions.length}
          onChange={handlePageChange}
          defaultValue={pageIndex + 1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Stack>
    );
  };

  const PageSize = () => {
    // whiteSpace - noWrap to prevent text from wrap
    return (
      <Stack direction="row" spacing="4" align="center">
        <Text whiteSpace="nowrap">Rows per page:</Text>
        <Select
          fontWeight="bold"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {['5', '10', '15', '20', '25', '30'].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </Stack>
    );
  };

  const Pagination = () => {
    return (
      <Flex justifyContent="flex-end" m={2} alignItems="center" pt="6">
        <Stack align="center" direction="row" spacing="10" mr="10">
          <PageSize />
          <PageLocation />
        </Stack>
        <Stack direction="row" spacing="4" mr="10">
          <ChangePage
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            direction={CHANGE_PAGE_DIRECTIONS.FIRST}
          />
          <ChangePage
            onClick={previousPage}
            isDisabled={!canPreviousPage}
            direction={CHANGE_PAGE_DIRECTIONS.PREVIOUS}
          />
        </Stack>
        <Stack direction="row" spacing="4">
          <ChangePage
            onClick={nextPage}
            isDisabled={!canNextPage}
            direction={CHANGE_PAGE_DIRECTIONS.NEXT}
          />
          <ChangePage
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
            direction={CHANGE_PAGE_DIRECTIONS.LAST}
          />
        </Stack>
      </Flex>
    );
  };

  return (
    <Stack direction="column" {...other}>
      <Table {...getTableProps()}>
        {caption && (
          <TableCaption
            fontSize="4xl"
            fontWeight="black"
            placement="top"
            pb="8"
            color="blackAlpha.900"
            letterSpacing="tight"
          >
            {caption}
          </TableCaption>
        )}
        <Thead>
          {headerGroups.map(headerGroup => {
            const {
              key,
              ...restHeaderGroupProps
            } = headerGroup.getHeaderGroupProps();
            return (
              <Tr key={key} {...restHeaderGroupProps} bgColor="">
                {headerGroup.headers.map(column => {
                  const { key, ...restColumnProps } = column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  );
                  return (
                    <Th
                      key={key}
                      {...restColumnProps}
                      fontSize="lg"
                      textTransform="initial"
                      letterSpacing="tight"
                      color="blackAlpha.900"
                      w={column.customWidth ? `${column.customWidth}px` : null}
                      py={6}
                    >
                      {column.render('Header')}
                      <chakra.span>
                        <SortIcon column={column} />
                      </chakra.span>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <Tr key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <Td key={key} {...restCellProps} py={6}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {data?.length > 0 ? <Pagination /> : null}
    </Stack>
  );
}

export const MemoChakraReactTable = React.memo(ChakraReactTable);

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool
};
ChakraReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  caption: PropTypes.string,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  row: PropTypes.object,
  isSelectable: PropTypes.bool,
  setSelectedIds: PropTypes.func
};
