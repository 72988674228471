import { v4 as uuidv4 } from 'uuid';
import {
  getRandomDate,
  getRandomItemFromArray,
  getRandomNumber,
  makeMethodAsync,
  copyObject
} from '../../utils/helpers/common.helpers';

const getRandomCurrency = currencies => {
  const randomContinentCurrency = getRandomItemFromArray(currencies);
  return getRandomItemFromArray(randomContinentCurrency?.options);
};

export const getCurrencyFromId = (currencies, idToRetrieve) => {
  const filteredCurrencies = currencies.filter(
    currency => currency.id === idToRetrieve
  );
  if (filteredCurrencies.length === 0) {
    return {};
  }
  return filteredCurrencies[0];
};

const getCurrencyOptionCountryData = ({ cc, name, cc1 }) => ({
  value: cc,
  label: `${cc} - ${name}`,
  cc1
});

export const getCurrencies = () => {
  return fetch('/currencies/currencies.json', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
};

export const getCurrenciesOptionsData = (rawCurrencies = []) => {
  return rawCurrencies.map(currency => {
    const label = currency.continent;
    const options = currency.options.map(currency =>
      getCurrencyOptionCountryData(currency)
    );
    return { label, options };
  });
};

export const prepareCurrencyAfterUserSubmit = (
  submittedCurrency,
  id = uuidv4()
) => {
  const submittedCurrencyCopy = copyObject(submittedCurrency);
  submittedCurrencyCopy.validDate = new Date(submittedCurrency.validDate);
  submittedCurrencyCopy.dateOfAddition = new Date();
  submittedCurrencyCopy.id = id;
  submittedCurrencyCopy.exchangeRate = parseFloat(
    submittedCurrencyCopy.exchangeRate
  );
  return submittedCurrencyCopy;
};

export const getFakeAlreadySubmittedCurrencies = async (limit = 0) => {
  if (limit === 0) {
    limit = getRandomNumber(10, 30);
  }

  const currencies = await (await getCurrencies()).json();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  let submittedCurrencies = [];

  for (let i = 0; i < limit; i++) {
    const fromRandomCurrency = getRandomCurrency(currencies);
    const toRandomCurrency = getRandomCurrency(currencies);

    if (fromRandomCurrency !== toRandomCurrency) {
      const dateOfAddition = getRandomDate(
        new Date(currentYear - 2, 0),
        currentDate
      );

      const submittedCurrency = {
        dateOfAddition,
        exchangeRate: getRandomNumber(0, 10, true),
        from: getCurrencyOptionCountryData(fromRandomCurrency),
        to: getCurrencyOptionCountryData(toRandomCurrency),
        validDate: getRandomDate(dateOfAddition, currentDate),
        id: uuidv4()
      };
      submittedCurrencies.push(submittedCurrency);
    } else {
      limit++;
    }
  }

  const callback = resolve => {
    resolve(submittedCurrencies);
  };

  return makeMethodAsync({
    resolveCallback: callback,
    rejectReturn: 'Ooops 🙈! Failed to fetch currencies from the server'
  });
};
