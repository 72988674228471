import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';

export default function BaseDialog({ body, footer, isOpen, onClose }) {
  const ref = useRef();
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={ref}
        closeOnOverlayClick={false}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogCloseButton zIndex="popover" />
          <AlertDialogBody py="0">{body}</AlertDialogBody>
          <AlertDialogFooter ref={ref} p="2" justifyContent="center">
            {footer}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

BaseDialog.propTypes = {
  body: PropTypes.node,
  footer: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};
