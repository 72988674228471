import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';
import { LAYOUT_CONSTANTS } from '../../../../utils/constants/layout.constants';
import { sideBarItems } from './sidebar.items';
import { FiHome } from 'react-icons/fi';

import SideBarItem from './SideBarItem/SideBarItem';
import Logo from './Logo/Logo';
import SideBarCategory from './SideBarCategory/SideBarCategory';
import { ROUTES } from '../../../../routes/routes';
export default function SideBar() {
  return (
    <Box
      position="fixed"
      left="0"
      py={5}
      w={LAYOUT_CONSTANTS.SIDEBAR.WIDTH}
      top="0"
      h="full"
      boxShadow="lg"
      bg="white"
    >
      <Logo text="Bot Playground" />
      <SideBarItem icon={FiHome} id="home" path={ROUTES.HOME}>
        Home
      </SideBarItem>
      {sideBarItems.map(({ categoryName, categoryItems }) => (
        <SideBarCategory
          key={categoryName}
          categoryName={categoryName}
          categoryItems={categoryItems}
        />
      ))}
    </Box>
  );
}
