import React from 'react';
import PropTypes from 'prop-types';

import { HStack, VStack, Text } from '@chakra-ui/react';
import TooltipExtended from '../../../../extended/TooltipExtended/TooltipExtended';
import IconButtonExtended from '../../../../extended/IconButtonExtended/IconButtonExtended';

import { FiX } from 'react-icons/fi';

export default function Notification({
  title,
  description,
  date,
  isLast,
  id,
  setNotifications
}) {
  const handleNotificationRemove = () => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== id)
    );
  };
  return (
    <HStack
      pl={2}
      pr={4}
      py={3}
      align="start"
      justify="space-between"
      _hover={{ backgroundColor: 'blue.50' }}
      borderBottom={!isLast && '1px'}
      borderBottomColor="gray.300"
    >
      <HStack spacing={2} cursor="pointer">
        <TooltipExtended label="Remove notification">
          <IconButtonExtended
            aria-label="Remove notification"
            size="sm"
            icon={<FiX />}
            colorScheme="black"
            onClick={handleNotificationRemove}
          />
        </TooltipExtended>
        <VStack align="start" spacing="0.5">
          <Text fontSize="md" fontWeight="semibold">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.700">
            {description}
          </Text>
          <Text textAlign="right" pr={2} fontSize="xs" color="gray.500">
            {date}
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
}

Notification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.number,
  isLast: PropTypes.bool,
  setNotifications: PropTypes.func
};
