import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

export default function ConfirmAlert({ title, description }) {
  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      backgroundColor="white"
      pb="0"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {title}
      </AlertTitle>
      <AlertDescription pt="2" maxWidth="sm">
        {description}
      </AlertDescription>
    </Alert>
  );
}

ConfirmAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
