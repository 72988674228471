import React, { useMemo } from 'react';
import { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const CurrenciesStateContext = createContext();
const CurrenciesDispatcherContext = createContext();

const ACTIONS = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  SET: 'SET',
  DROP: 'DROP'
};

const getCurrency = (currencies, idToRetrieve) => {
  const filteredCurrencies = currencies.filter(
    currency => currency.id === idToRetrieve
  );
  if (filteredCurrencies.length === 0) {
    return {};
  }
  return filteredCurrencies[0];
};

const updateCurrency = (currencies, updatedCurrency) => {
  return currencies.map(currency => {
    if (currency.id === updatedCurrency.id) {
      return updatedCurrency;
    }
    return currency;
  });
};

const removeCurrency = (currencies, currenciesIdsToRemove) => {
  const filteredCurrencies = currencies.filter(currency => {
    return !currenciesIdsToRemove.includes(currency.id);
  });
  return filteredCurrencies;
};

function currenciesReducer(state, action) {
  switch (action.type) {
    case ACTIONS.CREATE: {
      return [action.payload, ...state];
    }
    case ACTIONS.READ: {
      return getCurrency(state, action.payload);
    }
    case ACTIONS.UPDATE: {
      return updateCurrency(state, action.payload);
    }
    case ACTIONS.DELETE: {
      return removeCurrency(state, action.payload);
    }
    case ACTIONS.SET: {
      return [...state, ...action.payload];
    }
    case ACTIONS.DROP: {
      return [];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CurrenciesProvider({ children }) {
  const [reducerState, reducerDispatch] = useReducer(currenciesReducer, []);
  const [state, dispatch] = useMemo(() => [reducerState, reducerDispatch], [
    reducerState
  ]);

  return (
    <CurrenciesStateContext.Provider value={state}>
      <CurrenciesDispatcherContext.Provider value={dispatch}>
        {children}
      </CurrenciesDispatcherContext.Provider>
    </CurrenciesStateContext.Provider>
  );
}

function useCurrenciesState() {
  const currenciesState = useContext(CurrenciesStateContext);
  if (typeof currenciesState === 'undefined') {
    throw new Error(
      'useCurrenciesState must be used within a CurrenciesProvider'
    );
  }
  return currenciesState;
}

function useCurrenciesDispatcher() {
  const currenciesDispatcher = useContext(CurrenciesDispatcherContext);
  if (typeof currenciesDispatcher === 'undefined') {
    throw new Error(
      'useCurrenciesDispatcher must be used within a CurrenciesProvider'
    );
  }
  return currenciesDispatcher;
}

CurrenciesProvider.propTypes = {
  children: PropTypes.node
};

export {
  CurrenciesProvider,
  useCurrenciesState,
  useCurrenciesDispatcher,
  ACTIONS
};
