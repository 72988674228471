import { useEffect, useState } from 'react';
import { getTodos, setTodos } from '../services/todo/todoServices';

export function useTodos() {
  const [tasks, setTasks] = useState(() => getTodos());

  useEffect(() => {
    setTodos(tasks);
  }, [tasks]);

  return [tasks, setTasks];
}
