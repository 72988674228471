import React from 'react';

import { Stack, Text, FormControl } from '@chakra-ui/react';

import AuthForm from '../../../components/form/auth/AuthForm/AuthForm';
import AuthHeading from '../../../components/form/auth/AuthHeading/AuthHeading';
import AuthSubmitButton from '../../../components/form/auth/AuthSubmitButton/AuthSubmitButton';
import InputExtended from '../../../components/form/elements/InputExtended/InputExtended';
import FormLabelExtended from '../../../components/form/elements/FormLabelExtended/FormLabelExtended';
import LinkExtended from '../../../components/extended/LinkExtended/LinkExtended';

import { ROUTES } from '../../../routes/routes';

export default function ForgotPasswordForm() {
  return (
    <AuthForm>
      <Stack align="center" direction="column" spacing={8}>
        <AuthHeading>Forgot your password?</AuthHeading>
        <Text textAlign="center" fontSize="md">
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password
        </Text>
        <Stack direction="column" spacing={8}>
          <Stack direction="column" align="center">
            <FormControl isRequired>
              <FormLabelExtended>Email Address</FormLabelExtended>
              <InputExtended type="email" />
            </FormControl>
            <Text fontSize="sm">
              Remember your password?{' '}
              <LinkExtended to={ROUTES.AUTH.LOGIN}>Sign in here</LinkExtended>
            </Text>
          </Stack>
          <AuthSubmitButton>Request Reset</AuthSubmitButton>
        </Stack>
      </Stack>
    </AuthForm>
  );
}
