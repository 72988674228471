const padFloatingNumber = (number, precision) => {
  return parseFloat(number.toFixed(precision));
};

export const getRandomNumber = (
  rangeStart = 0,
  rangeEnd = 1,
  isDecimal = false,
  precision = 3
) => {
  const min = isDecimal ? rangeStart : Math.ceil(rangeStart);
  const max = isDecimal ? rangeEnd : Math.floor(rangeEnd);

  const getAnyRandomNumberInRange = () => {
    return Math.random() * (max - min + 1) + min;
  };

  return isDecimal
    ? padFloatingNumber(getAnyRandomNumberInRange(), precision)
    : Math.floor(getAnyRandomNumberInRange());
};

export const getRandomDate = (from, to) => {
  from = from.getTime();
  to = to.getTime();
  return new Date(from + Math.random() * (to - from));
};

export const getRandomItemFromArray = (arr = []) => {
  return arr[getRandomNumber(0, arr.length - 1)];
};

export const makeMethodAsync = ({
  resolveCallback = () => {},
  rejectReturn = {},
  conditionToCheckToResolve = true
}) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (getRandomNumber(1, 100) !== 70 && conditionToCheckToResolve) {
        resolveCallback(resolve);
      }
      reject(rejectReturn);
    }, getRandomNumber(1000, 10000));
  });
};

export const delayRedirect = callback => {
  setTimeout(() => {
    callback();
  }, 2800);
};

export const makeFunctionFailRandomly = (fn = () => {}, errorMessage = '') => {
  const randomNumber = getRandomNumber(1, 15);
  if (randomNumber !== 1) {
    return fn();
  } else {
    throw new Error(errorMessage);
  }
};

export const copyObject = object => {
  return JSON.parse(JSON.stringify(object));
};
